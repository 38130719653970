<template>
    <div class="monitoring_data">
        <router-view
            ref="route"
            class="basic-container"
            :itemName="itemName"
            :itemId="itemId"
            :currentChild.sync="currentChild"
        ></router-view>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
    name: "monitoring_data",
    components: {},
    data() {
        return {
            itemId: "90305",
            itemName: "遥感专项监测",
            currentChild: {
                ItemID: 90305,
                MC: "遥感专项监测",
            },
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    mounted() {
        this.$router.push({
            name: "remote_sense",
            params: this.currentChild,
        });
    },
    methods: {
        ...mapActions([""]),
    },
};
</script>
<style lang="scss" scoped>
.monitoring_data {
    height: calc(100% - 30px);
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    background: url("~@image/public/not-development.png") center center
        no-repeat;
    .basic-container {
        flex: 1;
        background: #f7f5fb;
    }
}
</style>
